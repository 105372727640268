import SearchForm from '@components/SearchForm';
import { removeBaseUrl } from '@utils/url';
import Link from 'next/link';
import { useEffect, useState } from 'react';

interface SearchElement {
  searchwords: Array<string>;
  text: string;
  url_path: string;
}

type Props = {
  visible_links: number;
  search_start: number;
  buttons: SearchElement[];
};

function Fakesearch(props: Props) {
  const { buttons, visible_links, search_start } = props;

  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [searchButtons, setSearchButtons] = useState(() => buttons);

  useEffect(() => {
    if (searchInput === null) return;
    if (searchInput === '') {
      setSearchButtons([...buttons]);
      return;
    }
    if (searchInput.length >= search_start) {
      const timeout = setTimeout(
        () =>
          setSearchButtons(() =>
            buttons.filter(button =>
              button.searchwords.join(';').toLowerCase().includes(searchInput.toLowerCase()),
            ),
          ),
        500,
      );
      return () => clearTimeout(timeout);
    }
  }, [searchInput, search_start, buttons]);

  return (
    <div className='search container'>
      <div className='wrapper'>
        <SearchForm
          inputValue={searchInput ?? ''}
          onInputChange={event => setSearchInput(event.target.value)}
          placeholder='Ich suche...'
        />
        <div className='search__link-container'>
          {searchButtons.slice(0, visible_links).map(button => (
            <Link
              href={removeBaseUrl(button.url_path)}
              key={button.text}
              passHref
              className='search__link button'
              rel='noreferrer'
            >
              {button.text}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Fakesearch;
export type { Props };
