import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import React from 'react';

type Props = {
  inputValue?: string;
  onInputChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  className?: string;
  placeholder?: string;
  hideSearchButton?: boolean;
};

function SearchForm(props: Props) {
  const { inputValue, onInputChange, className, placeholder } = props;

  return (
    <form
      method='GET'
      action={props.hideSearchButton ? '' : '/suche'}
      role='search'
      className={clsx('search-form', className)}
    >
      <input
        type='text'
        name='search'
        className='search-form__input'
        value={inputValue}
        onChange={onInputChange}
        placeholder={placeholder}
        aria-label='Suchwort eingeben'
      />
      {props.hideSearchButton ? null : (
        <button className='search-form__submit' type='submit' aria-label='Suche abschicken'>
          <MagnifyingGlassIcon color='#0c2f6e' width={64} height={48} />
        </button>
      )}
    </form>
  );
}

export default SearchForm;
